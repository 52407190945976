import React, { memo, useCallback, useState } from 'react';
import { Button, Table, Dropdown, Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import './managerReport.scss';
import LoadingSpinner from '../Loading-Component/Loading';
import logo from '../../images/manager-report/stonegate-logo.svg';
import opusMainLogo from '../../images/manager-report/opus-logo.svg';
import opusLogos from '../../images/manager-report/opus-logos.png';
import { useEffect, useMemo } from 'react';
import { apiUrl } from '../../config.js';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import { BsArrowDownRightSquare } from "react-icons/bs";
import { BsArrowUpRightSquare } from "react-icons/bs";
import { BsExclamationOctagonFill } from "react-icons/bs";
import { useParams } from 'react-router';
import { useDeviceInfo } from '../DeviceInfo';

export function ManagerReport() {

    const [mangerreport, setmangerreport] = useState([]);
    const [DataAfterFilter, setDataAfterFilter] = useState([]);
    const [loading, setLoading] = useState(false);
    const [Percentagevals, setPecentagevals] = useState([]);
    const [filteredpercentages, setfilteredpercentages] = useState([]);
    const [ReponseMessage, setResponseMessage] = useState("");
    const [GraphData, setGrapData] = useState([]);
  
    const { deviceInfo } = useDeviceInfo();



    const params = useParams();


    async function GetPercentages() {
        if (deviceInfo !== '') {

            try {
                var res = await fetch(`${apiUrl}/StonegateReport/GetPercentagevalues`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',

                    },
                    body: JSON.stringify({ Email: params.email, Guid: params.guid, Deviceinfo: deviceInfo })
                });

                const response = await res.json();
                if (response.message != null) {
                    setLoading(false);
                    window.alert(response.item3);

                }
                else {
                    setPecentagevals(response.item1);
                    setGrapData(response.item2);


                }
            }
            catch (ex) {
                console.log(ex);
                setLoading(false);
            }
        }
    }
    const ReportNotice = () => {
        return (
            <div className="report-notice text-center text-white my-4">
                <BsExclamationOctagonFill />
                <h3>{ReponseMessage}</h3>

            </div>
        )
    }




    useEffect(() => {
        async function Getreporttable() {
            if (deviceInfo !== '') {
                setLoading(true);
                try {
                    var res = await fetch(`${apiUrl}/StonegateReport/GetMangerReport`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',

                        },
                        body: JSON.stringify({ Email: params.email, Guid: params.guid, Deviceinfo: deviceInfo })
                    });

                    const result = await res.json();

                    if (result.message != null) {
                        setLoading(false);
                        window.alert(result.item2);

                    }
                    else if (result.item1[0].hasOwnProperty('response')) {

                        setResponseMessage(result.item1[0].response);
                        setLoading(false);


                    }
                    else {
                        setmangerreport(result.item1);
                        setDataAfterFilter(result.item1);
                        setLoading(false);
                        //GetPercentages();
                    }
                }
                catch (ex) {
                    console.log(ex);
                    setLoading(false);
                }

            }
        }
        document.title = "C247 + Stonegate Manger Report"


        GetPercentages();
        Getreporttable();

    }, [params, deviceInfo]);



    const formatethedate = (passeddate) => {
        if (!passeddate) {
            return passeddate;
        }
        else {
            const datePart = passeddate.split('T')[0];
            const [year, month, day] = datePart.split('-');
            return `${day}/${month}/${year}`;
        }
    }


    useEffect(() => {
        if (Percentagevals.length > 0) {

            setfilteredpercentages(Object.entries(Percentagevals[0])
                .reduce((acc, [key, value]) => {
                    if (key.toLowerCase().includes("percent")) {
                        acc[key] = value;
                    }
                    return acc;
                }, {})
            );

        }

    }, [Percentagevals]);


    //const [copyoffilters, setcopyoffilters] = useState(filteringcolums);

    const CalulateNetCost = (data) => {
        const total = data.reduce((total, item) => total + Number(item.nettCost), 0);
        return Number(total.toFixed(2));
    };

    const CalulateTotalRecovered = (data) => {
        const totalNegative = data.reduce((total, item) => {
            const cost = Number(item.nettCost);
            return cost < 0 ? total + cost : total;
        }, 0);
        if (totalNegative === 0) {
            return Number(totalNegative.toFixed(2));
        }
        else {
            return Number(totalNegative.toFixed(2) * -1); // Rounds the total negative cost to 2 decimal places and -1 to make it postive value
        }

    };

    const Bannerdata = () => {

        const [copyofDatafiltered, setcopyofDatafiltered] = useState(DataAfterFilter);

        const Symbolandvalue = ({ Dept }) => {

            if (Dept === 'Security') {
                return (
                    <div className="d-flex align-items-center">
                        <h4 className="mb-0" style={{ color: filteredpercentages.securityIncreasePercent > 0 ? 'red' : 'green' }}>{filteredpercentages.securityIncreasePercent}%</h4>
                        <span>{filteredpercentages.securityIncreasePercent > 0 ? <BsArrowUpRightSquare fill="red" /> : <BsArrowDownRightSquare fill="green" />}</span>
                    </div>
                )
            }
            if (Dept === 'Cleaning') {
                return (
                    <div className="d-flex align-items-center">
                        <h4 className="mb-0" style={{ color: filteredpercentages.cleaningIncreasePercent > 0 ? 'red' : 'green' }}>{filteredpercentages.cleaningIncreasePercent}%</h4>
                        <span>{filteredpercentages.cleaningIncreasePercent > 0 ? <BsArrowUpRightSquare fill="red" /> : <BsArrowDownRightSquare fill="green" />}</span>
                    </div>
                )
            }
            if (Dept === 'Facilities Management') {
                return (
                    <div className="d-flex align-items-center">
                        <h4 className="mb-0" style={{ color: filteredpercentages.fmIncreasePercent > 0 ? 'red' : 'green' }}>{filteredpercentages.fmIncreasePercent}%</h4>
                        <span>{filteredpercentages.fmIncreasePercent > 0 ? <BsArrowUpRightSquare fill="red" /> : <BsArrowDownRightSquare fill="green" />}</span>
                    </div>
                )
            }
            if (Dept === 'Entertainment') {
                return (
                    <div className="d-flex align-items-center">
                        <h4 className="mb-0" style={{ color: filteredpercentages.entertainmentIncreasePercent > 0 ? 'red' : 'green' }}>{filteredpercentages.entertainmentIncreasePercent}%</h4>
                        <span>{filteredpercentages.entertainmentIncreasePercent > 0 ? <BsArrowUpRightSquare fill="red" /> : <BsArrowDownRightSquare fill="green" />}</span>
                    </div>
                )
            }


        }


        if (copyofDatafiltered.length > 0) {
            return (

                <>

                    <div className="date statistics-bar__item d-xxl-flex align-items-center">
                        <p className="mb-0 me-2">Nett Cost:</p>
                        <h4 className="mb-0">&pound;{CalulateNetCost(copyofDatafiltered).toLocaleString(undefined, { maximumFractionDigits: 2 })}</h4>
                    </div>
                    <div className="date statistics-bar__item d-xxl-flex align-items-center">
                        <p className="mb-0 me-2">Total Recovered:</p>
                        <h4 className={`mb-0 ${CalulateTotalRecovered(copyofDatafiltered) > '0' ? 'text-success' : 'text-Danger'}`}>&pound;{CalulateTotalRecovered(copyofDatafiltered).toLocaleString(undefined, { maximumFractionDigits: 2 })}</h4>
                    </div>
                    <div className="date statistics-bar__item d-xxl-flex align-items-center">
                        <p className="mb-0 me-2">Manager:</p>
                        <h4 className="mb-0">{copyofDatafiltered[0].managerName}</h4>
                    </div>
                    <div className="date statistics-bar__item d-xxl-flex align-items-center">
                        <p className="mb-0 me-2">Site/Division:</p>
                        <h4 className="mb-0">{copyofDatafiltered[0].headerTitle}</h4>
                    </div>
                    <div>
                        <div className="mb-2 stat-header mb-2">
                            <p className="mb-2"><strong>Latest week on week change</strong></p>
                        </div>
                        <div className="d-flex">
                            <div className="statistic d-flex align-items-end me-4">
                                <span className="me-2">Security:</span>
                                <Symbolandvalue Dept='Security' />
                            </div>
                            <div className="statistic d-flex align-items-end me-4">
                                <span className="me-2">Cleaning:</span>
                                <Symbolandvalue Dept='Cleaning' />
                            </div>
                            <div className="statistic d-flex align-items-end me-4">
                                <span className="me-2">Facilities Management:</span>
                                <Symbolandvalue Dept='Facilities Management' />
                            </div>
                            <div className="statistic d-flex align-items-end me-4">
                                <span className="me-2">Entertainment:</span>
                                <Symbolandvalue Dept='Entertainment' />
                            </div>
                        </div>
                    </div>
                </>


            )
        }
    }

    const ReportTable = () => {
        return (
            <Table>
                <thead className="main-tableheader">
                    <tr>
                        <th>Invoice Received</th>
                        <th>Reported to Finance</th>
                        <th>Stonegate Period</th>
                        <th>Week</th>
                        <th>Date</th>
                        <th>Department</th>
                        <th>Site</th>
                        <th>BUN</th>
                        <th>Provider</th>
                        <th>Role</th>
                        <th>Start</th>
                        <th>Finish</th>
                        <th>Hours</th>
                        <th>Nett Cost</th>
                        <th>Challenge Cost</th>
                        <th>Notes</th>  
                        <th>Credited</th>
                    </tr>
                </thead>
                <tbody>
                    {DataAfterFilter.map((item) => (

                        <tr>
                            <td style={item.rowHighlightColour !== null ? { 'backgroundColor': item.rowHighlightColour } : item.credited ? { 'backgroundColor': '#ddd', 'textDecoration': 'line-through' } : { 'backgroundColor': 'white' }}>{formatethedate(item.invoiced)}</td>
                            <td style={item.rowHighlightColour !== null ? { 'backgroundColor': item.rowHighlightColour } : item.credited ? { 'backgroundColor': '#ddd', 'textDecoration': 'line-through' } : { 'backgroundColor': 'white' }}>{formatethedate(item.reportedToFinance)}</td>
                            <td style={item.rowHighlightColour !== null ? { 'backgroundColor': item.rowHighlightColour } : item.credited ? { 'backgroundColor': '#ddd', 'textDecoration': 'line-through' } : { 'backgroundColor': 'white' }}>{item.period}</td>
                            <td style={item.rowHighlightColour !== null ? { 'backgroundColor': item.rowHighlightColour } : item.credited ? { 'backgroundColor': '#ddd', 'textDecoration': 'line-through' } : { 'backgroundColor': 'white' }}>{formatethedate(item.weekending)}</td>
                            <td style={item.rowHighlightColour !== null ? { 'backgroundColor': item.rowHighlightColour } : item.credited ? { 'backgroundColor': '#ddd', 'textDecoration': 'line-through' } : { 'backgroundColor': 'white' }}>{formatethedate(item.jobdate)}</td>
                            <td style={item.rowHighlightColour !== null ? { 'backgroundColor': item.rowHighlightColour } : item.credited ? { 'backgroundColor': '#ddd', 'textDecoration': 'line-through' } : { 'backgroundColor': 'white' }}>{item.department}</td>
                            <td style={item.rowHighlightColour !== null ? { 'backgroundColor': item.rowHighlightColour } : item.credited ? { 'backgroundColor': '#ddd', 'textDecoration': 'line-through' } : { 'backgroundColor': 'white' }}>{item.site}</td>
                            <td style={item.rowHighlightColour !== null ? { 'backgroundColor': item.rowHighlightColour } : item.credited ? { 'backgroundColor': '#ddd', 'textDecoration': 'line-through' } : { 'backgroundColor': 'white' }}>{item.bun}</td>
                            <td style={item.rowHighlightColour !== null ? { 'backgroundColor': item.rowHighlightColour } : item.credited ? { 'backgroundColor': '#ddd', 'textDecoration': 'line-through' } : { 'backgroundColor': 'white' }}>{item.provider}</td>
                            <td style={item.rowHighlightColour !== null ? { 'backgroundColor': item.rowHighlightColour } : item.credited ? { 'backgroundColor': '#ddd', 'textDecoration': 'line-through' } : { 'backgroundColor': 'white' }}>{item.role}</td>
                            <td style={item.rowHighlightColour !== null ? { 'backgroundColor': item.rowHighlightColour } : item.credited ? { 'backgroundColor': '#ddd', 'textDecoration': 'line-through' } : { 'backgroundColor': 'white' }}>{item.start}</td>
                            <td style={item.rowHighlightColour !== null ? { 'backgroundColor': item.rowHighlightColour } : item.credited ? { 'backgroundColor': '#ddd', 'textDecoration': 'line-through' } : { 'backgroundColor': 'white' }}>{item.finish}</td>
                            <td style={item.rowHighlightColour !== null ? { 'backgroundColor': item.rowHighlightColour } : item.credited ? { 'backgroundColor': '#ddd', 'textDecoration': 'line-through' } : { 'backgroundColor': 'white' }}>{item.hours}</td>
                            <td style={item.rowHighlightColour !== null ? { 'backgroundColor': item.rowHighlightColour } : item.credited ? { 'backgroundColor': '#ddd', 'textDecoration': 'line-through' } : { 'backgroundColor': 'white' }}>&pound;{item.nettCost.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                            <td style={item.rowHighlightColour !== null ? { 'backgroundColor': item.rowHighlightColour } : item.credited ? { 'backgroundColor': '#ddd', 'textDecoration': 'line-through' } : { 'backgroundColor': 'white' }}>
                               
                                {item.challengeLink && (
                                    <Button variant="stonegateCta" onClick={(e) => window.open(item.challengeLink, '_blank')}>
                                        Challenge Cost
                                    </Button>
                                )}
                            </td>
                            <td style={item.rowHighlightColour !== null ? { 'backgroundColor': item.rowHighlightColour } : item.credited ? { 'backgroundColor': '#ddd', 'textDecoration': 'line-through' } : { 'backgroundColor': 'white' }}><p className="notes">{item.notes}</p></td>
                            <td style={item.rowHighlightColour !== null ? { 'backgroundColor': item.rowHighlightColour } : item.credited ? { 'backgroundColor': '#ddd', 'textDecoration': 'line-through' } : { 'backgroundColor': 'white' }}>{formatethedate(item.credited)}</td>
                        </tr>
                    ))

                    }
                </tbody>
            </Table>
        )
    }

    const handeldatachange = async (data,restflag) => {
     
        if (restflag) {
            setLoading(true);
            setDataAfterFilter(data);
          }
        else {
            setDataAfterFilter(data);
        }
     
 
    }
    useEffect(() => {
        if (JSON.stringify(DataAfterFilter) === JSON.stringify(mangerreport)) {
            setLoading(false);
        }
    }, [DataAfterFilter]) // Onclear to render the loader


    return (
        <div className="stonegate-container">
            <LoadingSpinner loading={loading} />
            <div className="report-header w-100 p-4 pt-4 pb-2 mb-2">
                <Row>
                    <Col md={12}>
                        <div className="text-light w-100 d-flex align-items-top justify-content-between">
                            <div>
                                <img src={logo} alt="Stonegate logo" />
                                <h4>Costings</h4>
                            </div>
                            <div>
                                <img src={opusMainLogo} alt="Opus Apiero logo" />
                            </div>
                        </div>
                        <Filterstop onDatachange={handeldatachange} DataBefore={mangerreport} DataAfter={DataAfterFilter} userparameter={params} />

                        <div className="statistics-bar d-block d-md-flex flex-wrap align-items-center p-2 p-md-4 my-3">
                            <Bannerdata />
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="manager-reports-container">
                <div className="mx-4 report-table-container">
                    {ReponseMessage ? <ReportNotice /> : <ReportTable />}
                </div>
                <div className="footer d-flex justify-content-end pe-4 mt-4">
                    <img src={opusLogos} alt="Opus Apiero" />
                </div>
            </div>
        </div>

    )

}


export const Filterstop = ({ onDatachange, DataBefore,DataAfter,userparameter }) => {

    const [sitesDdl, setsitesDdl] = useState([]);
    const [roleDdl, setroleDdl] = useState([]);
    const [departmentDdl, setDepartmentDdl] = useState([]);
    const [ReportedDdl, setReportedDd] = useState([]);
    const [PeriodDdl, setPeriodDdlDdl] = useState([]);
    const [weekworkedDdl, setweekworkedDdl] = useState([]);

   
  
    const filteringcolums = useMemo(() => ({
        department: '',
        site: '',
        role: '',
        reportedToFinance: '',
        period: '',
        weekending: ''
    }), []);

    const [filtervalues, setfiltervalues] = useState(filteringcolums);

    const formatethedate = (passeddate) => {
        if (!passeddate) {
            return passeddate;
        }
        else {
            const datePart = passeddate.split('T')[0];
            const [year, month, day] = datePart.split('-');
            return `${day}/${month}/${year}`;
        }
    }

    const updateFilterValues = (key, value) => {
        // Check if the value is truthy before updating the state
            setfiltervalues(prevState => ({
                ...prevState,
                [key]: value
            
            }));
       
    };


    useEffect(() => {
        if (DataAfter) {
            setsitesDdl(Array.from(new Set(DataAfter.map(item => item.site))));
            setroleDdl(Array.from(new Set(DataAfter.map(item => item.role))));
            setReportedDd(Array.from(new Set(DataAfter.map(item => item.reportedToFinance))));
            setPeriodDdlDdl(Array.from(new Set(DataAfter.map(item => item.period))));
            setweekworkedDdl(Array.from(new Set(DataAfter.map(item => item.weekending))));
            setDepartmentDdl(Array.from(new Set(DataAfter.map(item => item.department))));
        }

    }, [DataAfter]);

    useEffect(() => {
        if (JSON.stringify(filteringcolums) !== JSON.stringify(filtervalues)) {

            const datevaluecolumns = ['reportedToFinance', 'weekending'];
            const filteredtablevalues = DataAfter.filter(item => {
                return Object.keys(filtervalues).every(key => {
                   
                    if (!filtervalues[key]) return true;
                    if (datevaluecolumns.includes(key)) {

                        if (item[key] === null) {

                            return false;


                        } else {
                            // compare only the date part, ignoring the time
                            const itemDate = item[key].split('T')[0];
                            const filterDate = filtervalues[key].split('T')[0];
                            return itemDate === filterDate;
                        }
                    }

                    else {
                        // If the filter is set, only include items that match
                        return item[key] === filtervalues[key];
                    }

                });
            });

            onDatachange(filteredtablevalues,false);
            //setDataAfterFilter(filteredtablevalues);
            /*    setcopyoffilters(filtervalues);*/
        }


    }, [filtervalues]);
    const handelclearbutton = () => {
       
        onDatachange(DataBefore,true);
        setfiltervalues(filteringcolums);
  
    }


    const handelexcelexport = async (filtervals) => {
        filtervals.email = userparameter.email;
        filtervals.validationkey = userparameter.guid;
        try {
            var res = await fetch(`${apiUrl}/StonegateReport/ExportToCSV`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',

                },
                body: JSON.stringify(filtervals)
            });

            const response = await res.json();
            if (response.message != null) {
      
                window.alert(response.item2);

            }
            else {
               


            }
        }
        catch (ex) {
            console.log(ex);
            
        }
    
    }


    return (
        <div className="reports-filters-container d-flex flex-wrap align-items-end justify-content-between">
            <div className="d-flex">
                <div className="report-filters d-block d-md-flex flex-wrap align-items-end gap-3 my-2">
                    <Dropdown>
                        <Form.Label>Cost Type</Form.Label>
                        <Dropdown.Toggle variant="default" id="dropdown-basic">
                            <div>{filtervalues.department || 'Select Cost Type'}</div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {departmentDdl.map((items) => <Dropdown.Item onClick={(e) => updateFilterValues('department', items)}>{items}</Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown>
                        <Form.Label>Sites</Form.Label>
                        <Dropdown.Toggle variant="default me-0" id="dropdown-basic">
                            <div>{filtervalues.site || 'Select Site'}</div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {sitesDdl.map((items) => <Dropdown.Item onClick={() => updateFilterValues('site', items)}>{items}</Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown>
                        <Form.Label>Role</Form.Label>
                        <Dropdown.Toggle variant="default me-0" id="dropdown-basic">
                            <div >{filtervalues.role || 'Select Role'}</div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {roleDdl.map((items) => <Dropdown.Item onClick={(e) => updateFilterValues('role', items)}>{items}</Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown>
                        <Form.Label>Reported to Finance</Form.Label>
                        <Dropdown.Toggle variant="default" id="dropdown-basic">
                            <div className="fixed-width">{formatethedate(filtervalues.reportedToFinance) || 'Select Date'}</div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {ReportedDdl
                                .filter(dateStr => dateStr !== null) // Filter out null values
                                .sort((a, b) => a.localeCompare(b)) // Sort date strings in ascending order
                                .map(item => (
                                    <Dropdown.Item
                                        key={item}
                                        onClick={(e) => updateFilterValues('reportedToFinance', item)}
                                    >
                                        {formatethedate(item)}
                                    </Dropdown.Item>
                                ))
                            }
                        </Dropdown.Menu>

                    </Dropdown>
                    <Dropdown>
                        <Form.Label>Stonegate Period</Form.Label>
                        <Dropdown.Toggle variant="default" id="dropdown-basic">
                            <div >{filtervalues.period || 'Select Date'}</div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {PeriodDdl
                                .slice()
                                .sort((a, b) => a.localeCompare(b))
                                .map((items) =>
                                    <Dropdown.Item
                                        onClick={(e) => updateFilterValues('period', items)}>
                                        {items}</Dropdown.Item>
                                )}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown>

                        <Form.Label>Week worked</Form.Label>
                        <Dropdown.Toggle variant="default" id="dropdown-basic">
                            <div>{formatethedate(filtervalues.weekending) || 'Select Date'}</div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {weekworkedDdl
                                .filter(dateStr => dateStr !== null) // Filter out null values
                                .sort((a, b) => a.localeCompare(b)) // Sort date strings in ascending order
                                .map(item => (
                                    <Dropdown.Item
                                        key={item}
                                        onClick={(e) => updateFilterValues('weekending', item)}>
                                        {formatethedate(item)}
                                    </Dropdown.Item>
                                ))
                            }
                        </Dropdown.Menu>
                    </Dropdown>

                    <Button variant="primary clear-button" onClick={() => { handelclearbutton()}}>
                        Clear filters
                    </Button>

                </div>

            </div>
            <div>
                <Button variant="primary apply-button mb-2" onClick={() => { handelexcelexport(filtervalues); } } >
                    Export to CSV
                </Button>
            </div>
        </div >
    )
}
export default ManagerReport;