import React, { Component, Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import { AccessGroupProvider } from './AccessGroupContext';
import ManagerReport from './components/StongegateReport-Component/ManagerReport';
import UserDeviceProvider from './components/DeviceInfo';

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
        <UserDeviceProvider>
                <Fragment>
                
                    <Routes>
                        <Route path="/ManagerReport/:email/:guid" element={<ManagerReport />}></Route>
                    </Routes>
                        
                <AccessGroupProvider>
                    <Layout>
                        <Routes>
                            {AppRoutes.map((route, index) => {
                                const { element, ...rest } = route;
                                return <Route key={index} {...rest} element={element} />;
                            })}
                        </Routes>
                    </Layout>
                </AccessGroupProvider>
                </Fragment>
            </UserDeviceProvider>
            
        );
    }
}
