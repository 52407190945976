import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiUrl } from '../config.js';
import { Container, Row, Col, Form, Button, Image } from 'react-bootstrap';
import { MdInfoOutline } from "react-icons/md";
import { useCookies } from "react-cookie";
import CustomInput from './CustomInput-Component/CustomInput';
import OpusApeiro from '../images/NewLogos/OpusApeiro.png';
import C247FinalWhite from '../images/NewLogos/C247.png';
import SageLogo from '../images/Sage_DevelopmentPartner_Logo(White).svg';
import CyberEssentials from '../images/CyberEssentials_Logo_White(Web).svg';
import ISO9001 from '../images/ISO_9001_Registered_White.svg';
import ISO14001 from '../images/ISO_14001_Registered_White.svg';
import ISO27001 from '../images/ISO_27001_Registered_White.svg';
import UserIcon from '../images/User_Icon.svg';
import C247Final from '../images/C247-Final.svg';
import PadlockIcon from '../images/Padlock_Icon.svg';
import AppStoreButton from '../images/AppStoreButton.svg';
import GooglePlayButton from '../images/GooglePlayButton.svg';

import '../Styles/LoginPage.scss';
import { useEffect } from 'react';

export default function Login() {
    /*displayName: Login.name;*/

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isEnable, setEnable] = useState(true);
    const [cookies, setCookie] = useCookies(["jwt", "userid", "coid"]);
    const navigate = useNavigate();

    const handleKeyUp = () => {
        if (username.length > 0 && password.length > 0) setEnable(false);
        else setEnable(true);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const response = await fetch(`${apiUrl}/Login/AuthenticateAsync`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                'username': username,
                'password': password
            })
        });

        if (response.ok) {
            const logindetails = JSON.parse(await response.text())
            setCookie("jwt", logindetails.jwt, { path: "/", secure: true, httpOnly: false });
            setCookie("userid", logindetails.userid, { path: "/" });
            setCookie("coid", logindetails.coid, { path: "/" });
            setCookie("username", logindetails.username, { path: "/" });
            navigate('/screeningsummary');
        } else {
            setCookie("jwt", "", { path: "/" });
            setCookie("userid", "", { path: " / " });
            setCookie("coid", "", { path: "/" });
            setCookie("username", "", { path: "/" });
            alert('Invalid username or password');
        }
    };

    useEffect(() => {
        document.title="C247 + LoginPage"
    })

    return (
        <>
        <div className="login-page">     
            <div id="photoBackgroundLogo" className="component-container__photo-background" />
            <Container fluid> 
                <div className="header-container">
                    <img src={C247FinalWhite} id="headerContainerLogoSmallScreen" alt="logo" />
                    <img src={OpusApeiro} id="headerContainerLogo" />               
                </div>
                <Row className="justify-content-md-end align-items-sm-center flex-sm-column flex-md-row">
                <Col sm="5" md={{ span: 5, order: 1 }} xl="2" className="order-2">
                    <div className="login-container">
                        <Image src={C247Final} alt="Company Logo" className="logo" />
                        <div className="user-login-subtitle">
                            <p>User login</p>
                        </div>
                        <Form className="gap-2 flex-column align-items-center d-flex" onSubmit={handleSubmit}>
                               
                            <div>
                                <CustomInput
                                    name="userName"
                                    id="userName"
                                    label="USERNAME/MOBILE"
                                    type="text"
                                    value={username}
                                    iconStart={UserIcon}
                                    iconEnd={<MdInfoOutline style={{ width: "23px", height: "50px" }} />}
                                    onKeyUp={handleKeyUp}
                                    onChange={(event) => setUsername(event.target.value)}
                                />
                            </div>
            
                            <div>
                            <CustomInput
                                    name="password"
                                    id="password"
                                    label="PASSWORD/PIN"
                                    type="password"
                                    value={password}
                                    iconStart={PadlockIcon}
                                    iconEnd={<MdInfoOutline style={{ width: "23px", height: "50px" }} />} 
                                    onKeyUp={ handleKeyUp }
                                onChange={(event) => setPassword(event.target.value)} />
                            <div className="forgotten-password justify-content-end d-flex">
                                <p>Forgotten password?</p>
                            </div>
                            </div>
                               
                            <Button variant="primary" type="submit" value="Log In" className="login-button" disabled={ isEnable }> Login
                                </Button>
                              
                        </Form>
                        <div className="app-text">
                            <p>Download the app now</p>
                            <p>SEARCH C247</p>
                            </div>
                        <div className="store-icons">
                            <a href="https://apps.apple.com/gb/app/c247/id1150528676" target="_blank"><img src={AppStoreButton} /></a>
                            <a href="https://play.google.com/store/apps/details?id=com.continuum247.c247app3&hl=en_GB&gl=US" target="_blank"><img src={GooglePlayButton} /></a>
                        </div>
                    </div>
                </Col>
                <Col sm="5" md={{ span: 3, order: 2 }} className="order-1 d-flex right-side-title mt-5">
                <div className="title-instruction justify-content-center flex-column d-flex gap-2">
                    <span className="instruction">Please enter your login/mobile and password/pin to login.</span>
                    <span>0333 444 2472 | support@c247.it</span>
                    </div>
                </Col>
                </Row>
                <footer>
                    <div className="footer-text">
                        <p>Developing cutting edge solutions to everyday problems in multiple sectors across multiple continents</p>
                    </div>
                    <div className="footer-icons">
                        <img src={SageLogo} className="sage" alt="sage icon" />
                        <img src={CyberEssentials} alt="cyber essentials icon" />
                        <img src={ISO9001} alt="iso 9001 icon" />
                        <img src={ISO14001} alt="iso 14001 icon" />
                        <img src={ISO27001} alt="iso 27001 icon" />
                    </div>
                </footer>
            </Container>
        </div>
        </>
    );
}